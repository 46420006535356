<template>
  <div class="orderDetail biz-container">
    <b-modal id="modal-response" hide-footer header-class="border-0" v-model="modalVisible" centered>
      <template>
        <div class="w-full d-flex flex-column text-center px-5 pb-5">
          <span class="h4 mb-4" style="color:var(--biz-brand-button)">
            {{ $t('menu.purchase.detail.make_sure_you_received_your_order') }}
          </span>
          <button class="btn text-light corner mb-2 action-button" style="background:#015289"
            @click="onChangeStateOrder">
            {{ $t('menu.purchase.detail.already_received') }}
          </button>
          <button class="btn action-button" @click="modalVisible = false"
            style="border: solid 1px #015289; color:#015289">
            {{ $t('menu.purchase.detail.not_received_yet') }}
          </button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-response" hide-footer header-class="border-0" v-model="modalCancel" centered>
      <template>
        <div class="w-full d-flex flex-column px-5 pb-5">
          <div class="h4 mb-4" style="color:var(--biz-brand-button)">
            {{ $t('menu.purchase.detail.cancellation_reason') }}
          </div>
          <a-radio-group :value="selectedCancelReason" @change="handleCancelReasonChange"
            v-for="(item, index) in listCancelReason" :key="index">
            <a-radio :value="index" class="mb-2 text-dark">
              {{ item }}
            </a-radio>
          </a-radio-group>
          <button class="btn text-light mt-3 action-button" :disabled="selectedCancelReason === null"
            @click="onCancelByUser" style="background:#015289">
            {{ $t('menu.purchase.detail.confirmation') }}
          </button>
        </div>
      </template>
    </b-modal>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <div>
        <a-icon @click.prevent="() => $router.go(-1)" type="arrow-left"
          style="font-size: 20px; color: #000000; cursor: pointer" />
      </div>
      <div class="mx-3 orderDetail-icon">
        <order-detail-icon />
      </div>
      <div class="">
        <h3> {{ $t('purchase.detail.title') }}</h3>
      </div>
    </div>
    <a-row>
      <a-col :xs="24" :sm="24" :md="16" class="px-2 py-2">
        <a-row>
          <a-col :span="24" class="payment-information px-3 py-3">
            <a-row type="flex" wrap :justify="isMobileView ? 'space-around' : 'center'">
              <div class="d-flex align-items-center flex-column justify-content-between w-25"
                v-for="({ title, active, component, code }, key) in listProcess" :key="key">
                <div>
                  <component v-bind:is="component" :key="key" :active="active"></component>
                </div>
                <div class="mt-2"
                  :class="active ? code.includes('CANCELED')  ? 'text-cancel' : 'text-icon' : 'text-icon-pending'"> {{
                      title
                  }} </div>
              </div>
            </a-row>
          </a-col>

          <a-col v-if="data && data.payment_data && data.payment_data.term === 'CAD'" :span="24">
            <a-row v-if="dataMixPayment">
              <div v-if="dataMixPayment && dataMixPayment.credit_limit_status.toLowerCase() === 'unpaid' && ['BUYER_ACCEPTED'].includes(data.order_state)" class="section-bill-payment">
                <div class="d-flex">
                  <div class="mr-1">
                    <img
                      src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                      alt="info" />
                  </div>
                  <div>
                    {{ $t('menu.purchase.detail.bill_with_credit') }} <b>{{ dataMixPayment && toCurrency(dataMixPayment.credit_limit_amount) }}</b>.
                    {{ $t('menu.purchase.detail.pay_before_date') }} <b style="color: var(--biz-secondary-100)">{{ dataMixPayment && getDueDate(dataMixPayment.credit_limit_due_date) }}</b>
                  </div>
                </div>
              </div>
              <div v-else-if="dataMixPayment && dataMixPayment.credit_limit_status.toLowerCase() === 'unpaid'" class="section-bill-payment">
                <img
                  src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                  alt="info" /> {{ $t('menu.purchase.detail.bill_with_credit') }} <b>{{ toCurrency(dataMixPayment.credit_limit_amount) }}</b>
              </div>
              <div v-else-if="dataMixPayment && dataMixPayment.credit_limit_status.toLowerCase() === 'paid'" class="section-bill-payment-success">
                <a-alert message="Tagihan dengan pembayaran tempo pada pesanan ini telah lunas" type="success" show-icon/>
              </div>
              <div v-else-if="dataMixPayment && dataMixPayment.credit_limit_is_due === true" class="section-bill-payment-success">
                <a-alert :message="`Tagihan pembayaran tempo sebesar Rp ${toCurrency(dataMixPayment.credit_limit_amount) } pada pesanan ini telah melewati batas maksimal waktu pelunasan. Silahkan untuk melunasi terlebih dahulu tagihan tersebut`" type="error" show-icon/>
              </div>
            </a-row>
            <a-row v-else>
              <div v-if="cadBillStatus && cadBillStatus.credit_limit_status.toLowerCase() === 'unpaid' && ['BUYER_ACCEPTED'].includes(data.order_state)" class="section-bill-payment">
                <div class="d-flex">
                  <div class="mr-1">
                    <img
                      src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                      alt="info" />
                  </div>
                  <div>
                      Tagihan dengan pembayaran tempo pada pesanan ini sebesar <b>{{ cadBillStatus && toCurrency(cadBillStatus.credit_limit_amount) }}</b>.
                      Silahkan melunasi sebelum tanggal <b style="color: var(--biz-secondary-100)">{{ cadBillStatus && getDueDate(cadBillStatus.credit_limit_due_date) }}</b>
                  </div>
                </div>
              </div>
              <div v-else-if="cadBillStatus && cadBillStatus.credit_limit_status.toLowerCase() === 'unpaid'" class="section-bill-payment">
                <img
                  src="https://ik.imagekit.io/fepca/Group_15j3l_Yim.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659491692594"
                  alt="info" /> Tagihan dengan pembayaran tempo pada pesanan ini sebesar <b>{{ toCurrency(cadBillStatus.credit_limit_amount) }}</b>
              </div>
              <div v-else-if="cadBillStatus && cadBillStatus.credit_limit_status.toLowerCase() === 'paid'" class="section-bill-payment-success">
                <a-alert message="Tagihan dengan pembayaran tempo pada pesanan ini telah lunas" type="success" show-icon/>
              </div>
              <div v-else-if="cadBillStatus && cadBillStatus.credit_limit_is_due === true" class="section-bill-payment-success">
                <a-alert :message="`Tagihan pembayaran tempo sebesar Rp ${toCurrency(cadBillStatus.credit_limit_amount) } pada pesanan ini telah melewati batas maksimal waktu pelunasan. Silahkan untuk melunasi terlebih dahulu tagihan tersebut`" type="error" show-icon/>
              </div>
            </a-row>
          </a-col>

          <a-row>
            <a-col :span="24" class="d-flex flex-column payment-information mt-3 px-3 py-3">
              <div class="title d-flex justify-content-between align-items-center px-3 py-3">
                <div class="title" style="text-transform: uppercase">
                  {{ $t('purchase.detail.order_information') }}
                </div>
              </div>
              <div v-if="data && data.payment_data && data.payment_data.term !== 'CAD'" class="title d-flex justify-content-between align-items-center px-3 py-1">
                <div class="title-2"> {{ $t('purchase.detail.payment_status') }} </div>
                <div v-if="data && ['CANCELED'].includes(data.order_state)" class="status-payment-cancel w-auto">
                  {{ $t('menu.purchase.detail.cancelled') }}
                </div>
                <div
                  v-else-if="(data && data.payment_state === 'UNPAID') && !(data && ['CANCELED'].includes(data.order_state))"
                  class="status-payment-unpaid w-auto">
                  {{ $t('payment.unpaid') }}
                </div>
                <div class="status-payment-paid" v-else>{{ $t('payment.paid') }} </div>
              </div>
              <div class="title d-flex justify-content-between align-items-center px-3 py-2">
                <div class="title-2"> {{ $t('purchase.detail.payment_number') }} </div>
                <div class="title"> {{ data && data.order_number }} </div>
              </div>
              <div class="title d-flex justify-content-between align-items-center px-3 py-2">
                <div class="title-2"> {{ $t('purchase.detail.payment_date') }} </div>
                <div class="title"> {{ data && getDate(data.order_date) }} </div>
              </div>
              <div v-if="payment && payment.payment_term_code === 'COD'" class="title d-flex justify-content-between align-items-center px-3 py-2">
                <div class="title-2"> {{ $t('purchase.detail.total_payment') }} </div>
                <div class="total-payment"> {{ toCurrency(data.gross_total) }} </div>
              </div>
            </a-col>
          </a-row>
          <a-row :span="24" class="payment-method mt-3 px-3 py-3">
            <div class="title d-flex justify-content-between align-items-center px-3 py-3"
              style="text-transform: uppercase">
              <span>{{ $t('purchase.detail.payment_method') }}</span>
            </div>
            <div class="d-flex">
              <div :class="payment_type === 'BANK_TRANSFER' ? 'col-6 px-0' : 'col-12 px-0'">
                <div class="title d-flex justify-content-between align-items-center px-3 pb-1 pt-0 mb-1">
                  <div class="title-2"> {{ $t('purchase.detail.payment_type') }} </div>
                  <div class="status-payment2 mt-1">
                    <span>Coin</span>
                  </div>
                </div>
              </div>
              <div v-if="payment_type === 'BANK_TRANSFER'" class="d-flex col flex-column">
                <div class="title-2 mb-2">{{ $t('upload.uploadProofPayment') }}</div>
                <div v-if="image_struck && image_struck.url" class="d-flex flex-column justify-content-center">
                  <img :src="image_struck.url"
                    style="width: 100%; max-width: 100%; height: auto; border-radius: 13px; cursor: pointer;"
                    :alt="image_struck.url" @click.prevent="previewImage = true" />
                  <a-modal :visible="previewImage" :footer="null" @cancel="previewImage = false" centered>
                    <img :alt="image_struck.url" style="width: 100%" :src="image_struck.url" />
                  </a-modal>
                  <div v-if="Object.keys(dataMixPayment).length !== 0">
                    <a-upload v-if="dataMixPayment && dataMixPayment.status !== 'PAID'" name="file"
                      class="w-100 d-flex justify-content-center mt-2 wrapped-upload" :multiple="false" @change="addImage"
                      :show-upload-list="false" :beforeUpload="() => false">
                      <a-button class="upload-change mb-3" style="width: 100%">
                        {{ $t('upload.changeImage') }}
                      </a-button>
                    </a-upload>
                  </div>
                  <div v-else>
                    <a-upload v-if="payment && payment.status !== 'PAID'" name="file"
                      class="w-100 d-flex justify-content-center mt-2 wrapped-upload" :multiple="false" @change="addImage"
                      :show-upload-list="false" :beforeUpload="() => false">
                      <a-button class="upload-change mb-3" style="width: 100%">
                        {{ $t('upload.changeImage') }}
                      </a-button>
                    </a-upload>
                  </div>
                </div>
                <div v-else>
                  <a-form-item>
                    <a-upload-dragger name="image" :multiple="false" :show-upload-list="false" list-type="picture-card"
                      class="image-uploader" :beforeUpload="() => false" @change="addImage">
                      <div class="ant-upload-drag-icon">
                        <upload />
                      </div>
                      <div class="ant-upload">
                        {{ $t('form.pickPhoto') }}
                      </div>
                    </a-upload-dragger>
                  </a-form-item>
                </div>
                <span v-if="!image_struck.url" class="small-info">
                  {{ $t('menu.purchase.detail.max_file_size') }}
                </span>
              </div>
            </div>
          </a-row>
          <a-col :span="24" class="shipping-information mt-3 px-3 py-3">
            <div class="title d-flex justify-content-between align-items-center px-3 py-3"
              style="text-transform: uppercase"> {{ $t('purchase.detail.shipping_information') }}
            </div>
            <a-row class="px-3 py-1">
              <a-col :xs="24" :sm="24" :md="6" class="content-title">
                {{ $t('purchase.detail.recipient_name') }}
              </a-col>
              <a-col :xs="24" :sm="24" :md="18" class="content-value"> : {{
                  (data && data.destination_data) && data.destination_data.name
              }} </a-col>
            </a-row>
            <a-row class="px-3 py-1 mt-2">
              <a-col :xs="24" :sm="24" :md="6" class="content-title"> {{ $t('purchase.detail.address') }} </a-col>
              <a-col :xs="24" :sm="24" :md="18" class="content-value d-flex">
                <div>:</div>
                <div style="padding-left: 5px; line-height: 20px;">
                  {{ data && data.destination_data && data.destination_data.address }} <br />
                  {{ [
                      data && data.destination_data && data.destination_data.city,
                      data && data.destination_data && data.destination_data.province,
                      data && data.destination_data && data.destination_data.country,
                    ].join(', ')
                  }}{{ ' ' }}
                </div>
              </a-col>
            </a-row>

            <a-row class="px-3 py-1 mt-2">
              <a-col :xs="24" :sm="24" :md="6" class="content-title">
                {{ $t('purchase.detail.phone_number') }}
              </a-col>
              <a-col :xs="24" :sm="24" :md="18" class="content-value"> : {{ data && data.destination_data &&
                  data.destination_data.phone
              }} </a-col>
            </a-row>

            <a-row class="px-3 py-1 mt-2 mb-2">
              <a-col :xs="24" :sm="24" :md="6" class="content-title">
                {{ $t('purchase.detail.courier') }}
              </a-col>
              <a-col :xs="24" :sm="24" :md="18" class="content-value"> : {{
                data && data.shipment_data && data.shipment_data.courier
              }} </a-col>
            </a-row>
          </a-col>

          <a-col :span="24" class="product-information mt-3 px-3 py-3">
            <div class="title p-3" style="text-transform: uppercase">
              {{ $t('purchase.detail.product_detail') }}
            </div>
            <div class="d-flex align-items-center p-3">
              <WarehousePinIcon color="var(--biz-secondary-100)"/>
              <div class="warehouse-name ml-2"> {{ warehouseDetail && warehouseDetail.name }} </div>
            </div>
            <a-divider class="my-1" />
            <a-row class="px-3 py-3" v-for="(item, index) in data.items" :key="index">
              <a-col :xs="24" :sm="24" :md="4">
                <div>
                  <img :src="item.image_url" :alt="item.title" />
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="20">
                <a-row>
                  <a-col :span="16">
                    <div class="product-title"> {{ item.title }} </div>
                    <div class="mt-2 product-sku"> {{ item.qty }} {{ item.uom }} x {{ toCurrency(getTotalProductDiscount(item, item.selling_price, 'product')) }}
                    </div>
                  </a-col>
                  <a-col :span="8">
                    <div class="mt-2 product-title text-right price-1"> {{ toCurrency(getTotalProductDiscount(item, (item.selling_price * item.qty), 'total')) }}
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>

      <a-col :xs="24" :sm="24" :md="8" class="px-2 py-2">
        <a-row class="checkout-calculation px-4 py-4">
          <a-col class="title mb-3">
            {{ $t('purchase.checkout.order_summary') }}
          </a-col>

          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div> {{ $t('purchase.checkout.total_product') }} ({{ data.items ? data.items.length : 0 }} {{
                $t('purchase.checkout.item')
            }}) </div>
            <div style="color: var(--biz-secondary-100)"> {{ toCurrency(data.sub_total) }} </div>
          </div>
          <div v-if="discountTransaction.length > 0" >
            <div v-for="(item, index) in discountTransaction[0].data[0].promotion_data" :key="index">
              <div class="mt-3 d-flex justify-content-between align-items-center">
                <div> {{ item.name }} </div>
                <div style="color: #FF0000"> - {{ toCurrency(item.value) }} </div>
              </div>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div> PPN </div>
            <div style="color: var(--biz-secondary-100)"> - </div>
          </div>
          <!-- Coin Used Details -->
          <div v-if="data && data.loyalty_usage > 0" class="mt-3 d-flex justify-content-between align-items-center">
            <div> {{ $t('purchase.checkout.point_used') }} </div>
            <div style="color: var(--biz-secondary-100)"> {{ data.loyalty_usage }} </div>
          </div>
          <!-- Credit Used Details -->
          <div v-if="data && data.payment_data && data.payment_data?.term?.toUpperCase() === 'CAD'" class="mt-3 d-flex justify-content-between align-items-center">
            <div> Credit Limit </div>
            <div class="text-dark"> Rp {{ toCurrency(data.payment_data.credit_limit_usage) }} </div>
          </div>
          <div v-if="(data && data.deposit > 0)">
            <div class="mt-3 d-flex justify-content-between align-items-center">
              <div> {{ $t('purchase.checkout.return_balance') }} </div>
              <div style="color: var(--biz-secondary-100)"> - {{ toCurrency(data.deposit) }} </div>
            </div>
          </div>

          <a-divider />

          <div class="mt-3 mb-4 d-flex justify-content-between align-items-center">
            <div> {{ $t('purchase.checkout.grand_total') }} </div>
            <div class="total"> {{ toCurrency(data.gross_total - (data && data.payment_data && data.payment_data.credit_limit_usage || 0)) }} </div>
          </div>
          <span v-if="data && data.order_state === 'SHIPPING' || data && data.shipping_state && data.shipping_state?.toUpperCase() === 'SHIPPED' || data && data.shipping_state && data.shipping_state?.toUpperCase() === 'DELIVERED'" :span="24" @click.stop="modalVisible = true"
            class="btn confirm-payment mt-1 mb-2 d-flex align-items-center justify-content-center">
            {{ $t('menu.purchase.detail.order_received') }}
          </span>
          <a-col v-if="(data && ['WAITING_CONFIRMATION', 'SUBMITTED', 'VERIFIED'].includes(data.order_state))"
            :span="24" @click="modalCancel = true, selectedCancelReason = null"
            class="btn confirm-payment mt-1 mb-2 d-flex align-items-center justify-content-center">
            {{ $t('menu.purchase.detail.cancel_order') }}
          </a-col>
          <a-col :span="24" @click.prevent="() => $router.go(-1)"
            class="btn btn-back d-flex mt-1 align-items-center justify-content-center">
            {{ $t('menu.purchase.detail.back') }}
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import Localization from '@/localization'
import OrderDetailIcon from '@/components/Icons/DetailOrder.vue'
import OrderIcon from '@/components/Icons/Order.vue'
import ProcessIcon from '@/components/Icons/Process.vue'
import SendingIcon from '@/components/Icons/Sending.vue'
import OrderCancelIcon from '@/components/Icons/OrderCancel.vue'
import BoxIcon from '@/components/Icons/Box.vue'
import CopyIcon from '@/components/Icons/Copy.vue'
import WarehousePinIcon from '@/components/Icons/WarehousePin.vue'
import Upload from '@/components/Icons/Upload.vue'
import moment from 'moment'
import Vue from 'vue'
import PotensialCoins from '@/components/Purchase/Checkout/PotensialCoins/index.vue'

const staticListOrderProcess = [
  {
    title: 'Menunggu Konfirmasi',
    code: ['WAITING_CONFIRMATION', 'SUBMITTED', 'VERIFIED'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dalam Proses',
    code: ['ON_PROCESS', 'ACCEPTED'],
    active: false,
    component: Vue.extend(ProcessIcon),
  },
  {
    title: 'Pengiriman',
    code: ['SHIPPED', 'DELIVERED', 'BUYER_ACCEPTED'],
    active: false,
    component: Vue.extend(SendingIcon),
  },
  {
    title: 'Selesai',
    code: ['COMPLETED'],
    active: false,
    component: Vue.extend(BoxIcon),
  },
]
const staticListOrderCancel = [
  {
    title: 'Menunggu Konfirmasi',
    code: ['WAITING_CONFIRMATION', 'SUBMITTED', 'VERIFIED', 'CANCELREQUESTED'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dibatalkan',
    code: ['CANCELED'],
    active: false,
    component: Vue.extend(OrderCancelIcon),
  },
  {
    title: '',
    code: '',
    active: false,
    component: Vue.extend(),
  },
  {
    title: '',
    code: '',
    active: false,
    component: Vue.extend(),
  },
]
const cancelReason = [
  'Ingin mengubah alamat pengiriman',
  'Ingin memasukkan atau mengubah poin yang digunakan',
  'Ingin mengubah pesanan',
  'Ingin mengubah cara pembayaran',
  'Lainnya',
]
export default {
  name: 'purhcase.detail',
  components: {
    Localization,
    OrderDetailIcon,
    OrderIcon,
    ProcessIcon,
    SendingIcon,
    OrderCancelIcon,
    BoxIcon,
    CopyIcon,
    Upload,
    WarehousePinIcon,
    PotensialCoins,
    // PurchaseDetailComponent
  },
  data() {
    return {
      data: {},
      payment: null,
      modalVisible: false,
      modalCancel: false,
      image_struck: {
        file: '',
        url: '',
        fileList: [],
      },
      previewImage: false,
      payment_type: '',
      listProcess: [],
      warehouseDetail: null,
      selectedCancelReason: null,
      listCancelReason: cancelReason,
      discountTransaction: [],
      discountProduct: [],
      dataMixPayment: null,
      flagBuyerAccepted: true,
      localPointUsed: 0,
      creditLimitUsage: 0,
      dataDiscount: [],
      dataPercentage: [],
      dataTransactionType: [],
      cadBillStatus: null,
      infoReturn: {},
    }
  },
  computed: {
    ...mapState(['app']),
    loading: function () {
      return this.$store.state.purchase.loading
    },
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    checkout_status() {
      return this.data.status
    },
    pointUsed() {
      return this.$store.state.loyalty.usedPoints
    },
  },
  methods: {
    emitLocalUsedPoints(val) {
      this.localPointUsed = val
    },
    getTotalProductDiscount(obj, value, type) {
      const filter = this.discountProduct?.[0]?.data?.filter(v => { return v.catalog_id === obj.catalog_id })
      if (this.discountProduct.length > 0 && filter.length > 0) {
        if (type === 'total') {
          return value - filter[0].value
        } else {
          return Math.round(((obj.selling_price * obj.qty) - filter[0].value) / obj.qty)
        }
      } else {
        return value
      }
    },
    getDate(e) {
      return moment.utc(e).local().format('DD-MM-YYYY, HH:mm')
    },
    getDueDate(e) {
      const monthNames = ['Januari ', 'Februari ', 'Maret ', 'April ', 'Mei ', 'Juni ',
        'Juli ', 'Agustus ', 'September ', 'Oktober ', 'November ', 'Desember ',
      ]
      const date = new Date(e)
      return date.getDate() + ' ' + monthNames[date.getMonth()] + date.getFullYear()
    },
    addImage({ file }) {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      const seller_id = this.warehouseDetail?.seller_id
      const order_id = this.$route.params.order_id
      const account_number = this.payment ? this.payment.account_no : 0
      const seller_payment_method_id = this.payment ? this.payment.method_id : 0
      const bank_name = this.payment ? this.payment.bank : ''
      const cad_settlement = false
      const payment_id = this.payment ? this.payment.payment_id : ''
      if (!types.includes(file.type)) {
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Invalid format file',
        })
      }
      if (file.size > 10000000) {
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Image Size Max 10 MB',
        })
      }
      this.$store.dispatch('purchase/GETDETAILBANKACCOUNT', {
        account_number: this.data && this.data.payment_data.number,
        channel_id: this.$store.state.app.redeem_channel_id,
        seller_id,
      })
        .then(({ data }) => {
          return this.$store.dispatch('purchase/UPLOADPROOFPAYMENT', {
            order_id,
            channel_id: this.$store.state.app.redeem_channel_id,
            seller_id,
            account_id: data ? data.id : '',
            account_number,
            seller_payment_method_id,
            bank_name,
            cad_settlement,
            payment_id,
            image: file,
          }).then((res) => {
            this.$notification.success({
              message: res.message,
            })
            setTimeout(() => {
              this.$router.go(0)
            }, 1000)
          }).catch((err) => {
            console.log({ err })
            this.$notification.error({
              message: err.response.data.message,
            })
          })
        })
        .catch((err) => {
          console.log({ err })
          this.$notification.error({
            message: err,
          })
        })
    },
    removeImage() {
      this.image_struck = {
        file: '',
        url: '',
        fileList: [],
      }
    },
    onCopyVa(e) {
      return this.$notification.success({
        message: 'Copy Virtual Account to Clipboard Success',
      })
    },
    onError(e) {
      return this.$notification.error({
        message: 'copy to clipboard failed',
      })
    },
    fetchOrder() {
      this.$store.dispatch('purchase/GETDETAIL', {
        order_id: this.$route.params.order_id,
        channel_id: this.$store.state.app.redeem_channel_id || JSON.parse(Cookies.get('app_data'))?.redeem_channel_id,
      })
        .then(({ data }) => {
          // initial active process
          this.getWarehouseDetail(data.warehouse_id)
          if (data.promotions !== null) {
            this.discountTransaction = data.promotions?.discounts.filter(v => { return v.type === 'transaction' })
            this.discountProduct = data.promotions?.discounts.filter(v => { return v.type === 'product' })
          }
          let active = true
          this.data = data
          this.payment_type = data.payment_data.payment_type
          const newListProcess = (data && (data.order_state === 'CANCELED' || data.order_state === 'CANCELREQUESTED') ? staticListOrderCancel : staticListOrderProcess).map((item) => {
            const newItem = {
              ...item,
              active: data.order_state === 'ACCEPTED' && item.code.includes(data.shipping_state) ? true : active,
            }
            if (item.code.includes(data.order_state)) {
              active = false
            }
            return newItem
          })
          this.listProcess = newListProcess
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.$route.params.order_id,
          })
        })
        .then(({ data }) => {
          this.payment = data.payment
          return this.$store.dispatch('purchase/GETDETAILPAYMENT', {
            order_id: this.$route.params.order_id,
            channel_id: this.$store.state.app.redeem_channel_id,
          })
        })
        .then(({ data }) => {
          const order_id = this.$route.params.order_id
          this.getProofPayment(order_id, this.$store.state.app.redeem_channel_id)
          if (data) {
            this.payment = {
              ...this.payment,
              ...data,
              status: data.status,
            }
          }
          if (this.data?.payment_data?.mix_payment_method_id) {
            this.$store.dispatch('purchase/GETMIXPAYMENT', {
              order_id: this.$route.params.order_id,
              channel_id: this.$store.state.app.redeem_channel_id,
            })
              .then((result) => {
                if (this.data.payment_data.mix_payment_method_id !== null) {
                  if (result !== null) {
                    this.dataMixPayment = result
                  }
                } else {
                  this.dataMixPayment = null
                  this.cadBillStatus = result ?? null
                }
              })
          }
        })
        .catch((error) => {
          error.response.statusText && this.$notification.error({
            message: error.response.statusText,
          })
        })
    },
    getWarehouseDetail(warehouse_id) {
      this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
        id: [warehouse_id],
      })
        .then(({ data }) => {
          this.warehouseDetail = data[0] ?? null
        })
    },
    getProofPayment(order_id, channel_id) {
      this.$store.dispatch('purchase/GETPROOFPAYMENT', {
        order_id,
        channel_id,
      })
        .then(({ data }) => {
          this.image_struck = {
            file: '',
            url: data.image_url,
            fileList: [],
          }
        })
    },
    onCancelByUser() {
      this.$store.dispatch('purchase/CANCELORDERBYUSER', {
        order_id: this.$route.params.order_id,
        reason: this.listCancelReason[this.selectedCancelReason],
        reasonCode: `RS0${this.selectedCancelReason + 1}`,
      })
        .then(() => {
          this.modalCancel = false
          this.fetchOrder()
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    onChangeStateOrder() {
      this.$store.dispatch('purchase/ACCEPTEDORDERBYUSER', {
        order_id: this.$route.params.order_id,
      })
        .then(() => {
          this.modalVisible = false
          this.fetchOrder()
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    handleCancelReasonChange(e) {
      this.selectedCancelReason = e.target.value
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  created: function () {
    this.fetchOrder()
  },
}
</script>

<style lang="scss">
.orderDetail {
  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }

  .payment-information {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .confirm-payment {
      height: 33px;
      background: #FFFFFF;
      border: 1px solid var(--biz-brand-button) !important;
      // border-width: 1px;
      // border-color: var(--biz-brand-button);
      border-radius: 5px;
      color: var(--biz-brand-button);
      cursor: pointer;
    }

    .status-icon-done {
      background: var(--biz-brand-button);
      width: 60px;
      height: 60px;
      border-radius: 100px;
    }

    .status-icon-done-mobile {
      background: var(--biz-brand-button);
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }

    .status-icon-pending {
      background: #D2D2D2;
      border-radius: 100px;
      width: 60px;
      height: 60px;
    }

    .status-icon-pending-mobile {
      background: #D2D2D2;
      border-radius: 100px;
      width: 40px;
      height: 40px;
    }

    .text-icon {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: var(--biz-brand-button);
    }

    .text-icon-mobile {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: var(--biz-brand-button);
    }

    .text-icon-pending {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #767676;
    }

    .text-cancel {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FF0000;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .title-2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: light;
      font-size: 14px;
      line-height: 16px;
      color: #2B2B2B;
    }

    .total-payment {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: var(--biz-secondary-100);
    }

    .status-payment-paid {
      background: var(--biz-brand-button);
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 16px;
      font-size: 14px;
    }

    .status-payment-unpaid {
      font-size: 14px;
      background: var(--biz-secondary-100);
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 16px;
    }

    .status-payment-cancel {
      font-size: 14px;
      background: #FF0000;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 16px;
    }
  }

  .payment-method {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .status-payment2 {
      color: var(--biz-secondary-100);
    }

    .margin-width-copy {
      margin-left: auto;
      flex-direction: row;
    }

    .copy-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: var(--biz-brand-button);
      text-align: center;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .title-2 {
      font-style: normal;
      font-weight: light;
      font-size: 14px;
      line-height: 25px;
      color: #000000;
    }
  }

  .shipping-information {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .content-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #585858;
    }

    .content-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .product-information {
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    img {
      width: 97px;
      height: 93.62px;
    }

    .product-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }

    .product-sku {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8C8C8C;
    }

    .price-1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--biz-secondary-100);
    }

    .price-2 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }
  }
}

.checkout-calculation {
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }

  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--biz-secondary-100);
  }

  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: var(--biz-secondary-100);
  }

  .term-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
  }

  .confirm-payment {
    height: 33px;
    background: var(--biz-brand-button);
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.wrapper-upload {
  background: #FAFAFA;
  border: none;
}

.wrapper-upload:hover {
  background: #FAFAFA;
  border: none;
}

.image-uploader {
  .ant-upload-select-picture-card {
    margin: 0 !important;
    width: 140px !important;
    height: 140px !important;

    img {
      max-width: 100%
    }

    i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}

.upload-change {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid var(--biz-brand-button) !important;
  border-radius: 5px;
  color: var(--biz-brand-button);
  width: 100%;
}

.wrapped-upload>div {
  width: 100% !important;
}

.btn-back {
  background: #FFFFFF;
  border: 1px solid var(--biz-brand-button) !important;
  border-radius: 5px;
  color: var(--biz-brand-button);
  cursor: pointer;
  width: 100%;
}

.warehouse-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: var(--biz-brand-button);
}

.action-button {
  border-radius: 8px;
}

.payment-type-label {
  color: var(--biz-secondary-100);
}

.section-bill-payment {
  color: var(--biz-brand-button);
  background: #E6F6FF;
  border: 1px solid #B3E2FF;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 15px;

  span {
    font-weight: bold;
  }
}
.section-bill-payment-success {
  color: var(--biz-brand-button);
  background: #f4f4f4;
  margin-top: 20px;
  border-radius: 15px;

  span {
    font-weight: bold;
  }
}
.small-info {
  color: #b4b4b4;
  font-size: 12px;
  margin-top: -10px;
}
</style>
